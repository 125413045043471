<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()

// Watch for route changes
watch(
  () => route.path,
  () => {
    // Small delay to ensure DOM is updated
    setTimeout(() => {
      // Reset focus to the start of the page
      document.body.setAttribute('tabindex', '-1')
      document.body.focus()
      document.body.removeAttribute('tabindex')
    }, 0)
  },
)
</script>

<template>
  <div class="quick-links">
    <a href="#main-navigation" class="skip-link">
      {{ $t('accessibility.skipLinks.nav') }}
    </a>
    <a href="#search" class="skip-link">
      {{ $t('accessibility.skipLinks.search') }}
    </a>
    <a href="#main-content" class="skip-link">
      {{ $t('accessibility.skipLinks.content') }}
    </a>
  </div>
</template>

<style lang="scss" scoped>
.quick-links {
  position: absolute;
  left: 0;
  z-index: 1000;
}

.skip-link {
  position: absolute;
  width: max-content;
  transform: translateY(-100%);
  display: block;
  left: 0;
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  background: var(--c-primary);

  &:focus {
    transform: translateY(0);
  }
}
</style>
